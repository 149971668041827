<template>
    <section>
        <div class="container_services">
            <div>
                <img src="../assets/the concept.svg" width="82" height="42" alt="logo" />
            </div>

            <div class="font-weight-bold my-5 text-start" style="width:100%">
                {{ $t('Choose the services you want') }}...
            </div>
            <!-- Categories -->
            <div>
                <v-tabs v-model="categoryTab">
                    <div class="d-flex align-center">
                        <v-btn depressed color="accent" v-for="(category, index) in Object.keys(treeGroup)" :key="category"
                            width="105" height="27" :class="{ 'gray11 gray9--text': categoryTab !== index }"
                            class="rounded-pill ma-2" @click="selectCategory(treeGroup[category], index)">
                            {{ $t(category) }}</v-btn>
                    </div>
                    <!-- Category Services -->
                    <v-tab-item class="services-box" v-model="categoryTab">
                        <v-checkbox v-for="(service) in services" :key="service.id" v-model="selected_services"
                            :value="service.id" :label="service.name" hide-details color="accent">
                            <template v-slot:label>
                                <v-row justify="space-between">
                                    <v-col cols="auto">
                                        {{ service.name }}
                                    </v-col>
                                    <v-col cols="auto"> 
                                        {{ service.price_with_vat | float }} {{ $t('Riyal') }}
                                    
                                        <v-tooltip top v-if="service.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon color="accent" dark v-bind="attrs" v-on="on">
                                                    mdi-alert-circle-outline
                                                </v-icon>
                                            </template>
                                            <span>{{ service.description }}</span>
                                        </v-tooltip>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-checkbox>
                    </v-tab-item>
                    <!-- End Of Category Services -->
                </v-tabs>
            </div>
            <!-- End Of Categories -->

            <div class="navigation d-flex justify-end mt-13 mb-3 px-5" style="width:90%">
                <v-btn depressed color="accent" :disabled="!selected_services.length" width="131" height="45"
                    class="rounded-pill" @click="next">
                    {{ $t('next') }}</v-btn>
            </div>

        </div>
    </section>
</template>
  
<script>

export default {
    name: "Services",

    data: () => ({
        categoryTab: 0,
        services: [],
        selected_services: [],
        treeGroup: [],
    }),
    props: {
        navigationTab: { default: Function },
        data: { default: Object }
    },
    mounted() {
        if (this.data.services.length) {
            this.treeGroup = this.$global.GroupBy(this.data.services);
            this.services = this.treeGroup[this.data.services.at(0).category_name];
        }
    },
    computed: {},
    methods: {
        selectCategory(data, index) {
            this.categoryTab = index
            this.services = data
        },
        next() {
            if (this.selected_services.length) {
                this.navigationTab(1, { 'selectedServices': this.selected_services },)
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.container_services {
    text-align: center;
    padding: 30px;
    height: 100dvh;
    position: relative;
}

.v-input.v-input--hide-details.theme--light.v-input--selection-controls.v-input--checkbox {
    border: 0.857143px solid #A0A0A0;
    border-radius: 5px;
    margin: 8px 0;
    padding: 5px;
}

.services-box {
    overflow: auto;
    overflow-x: hidden;
    height: 60dvh;
}

.navigation {
    position: absolute;
    bottom: 2%;
}
</style>