<template>
    <section>
        <div class="container_welcome">
            <!-- animation images -->
            <img class="animation_img1" src="../assets/MakinAminAnimation.svg" alt="animation parallax">
            <img class="animation_img2" src="../assets/MakinAminAnimation2.svg" alt="animation parallax">
            <img class="animation_img3" src="../assets/MakinAminAnimation3.svg" alt="animation parallax">
            <!-- End animation images -->

            <div class="layer">
                <div class="font-weight-bold text-h4 gray5--text">
                    {{ $t('Welcome to') }}
                </div>

                <div class="content">
                    <img src="../assets/the concept.svg" alt="content logo" />
                </div>

                <v-btn depressed v-if="validateUrl" color="accent" width="163" height="48" class="rounded-pill"
                    @click="getData" :loading="pageData.isLoading">
                    {{ $t('Book now') }}</v-btn>
                <v-alert v-if="!validateUrl" border="right" colored-border type="error" elevation="2" class="mx-3">
                    {{ $t('The link is incorrect. Please verify the link and try again') }}
                </v-alert>
            </div>
        </div>
    </section>
</template>
  
<script>
import axios from 'axios';


export default {
    name: "Welcome",

    data: () => ({
        pageData: {
            screen_code: "03-004",
            url: null,
            controlRoute: "/website/qr/welcome",
            entityName: null,
            main: null,
            category: null,
            isLoading: false,
            isTrashed: 1,
            rowsNumber: 15,
            tableHeader: [],
            search: "",
            rows: [],
            options: {},
            page: 1,
            pagination: {},
            queryParam: '?'
        },
        responseData: {},
        isSelected: false,
    }),
    props: {
        navigationTab: { default: Function }
    },
    components: {
    },
    computed: {
        validateUrl() {
            return !this.$route.query.clientID || !this.$route.query.branch_id ? false : true;
        }
    },
    mounted() {
        axios.defaults.headers.common["clientID"] = this.$route.query.clientID || "test"
    },
    methods: {
        getData() {
            this.pageData.isLoading = true;
            this.$api.GET_METHOD(`create_booking`).then((response) => {
                this.pageData.isLoading = false;
                if (response.check) {
                    console.log('response', response);
                    this.responseData = response.data
                    this.responseData.branch_id = this.$route.query.branch_id || 0
                    this.navigationTab(1, this.responseData)
                }
            })
        },
    },
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');



* {
    font-family: 'Tajawal', sans-serif;
}

.container_welcome {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;
    overflow: hidden;
    padding: 0 !important;
}

body {
    box-sizing: border-box;
    margin: 0;
    overflow-x: hidden;
    position: relative;
}

.animation_img1,
.animation_img2,
.animation_img3 {
    position: absolute;
    width: 401px;
    height: 401px;
}

.animation_img1 {
    -webkit-animation: MoveCorner 12s infinite alternate;
    animation: MoveCorner 12s infinite alternate;
}

.animation_img2 {
    -webkit-animation: MoveCorner-reverse 12s infinite alternate;
    animation: MoveCorner-reverse 12s infinite alternate;
}

.animation_img3 {
    -webkit-animation: MoveCorner-UpDown 12s infinite alternate;
    animation: MoveCorner-UpDown 12s infinite alternate;
}


.layer {
    background: rgb(255 255 255 / 80%);
    backdrop-filter: blur(172px);
    -webkit-backdrop-filter: blur(172px);
    backdrop-filter: blur(172px);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: space-evenly;
    width: 100%;

}

.logo_container {
    width: 121px;
    height: 121px;
    filter: drop-shadow(0px 5px 79px rgba(128, 158, 255, 0.35));
    -webkit-filter: drop-shadow(0px 5px 79px rgba(128, 158, 255, 0.35));
    margin-bottom: 15px;
    border-radius: 50%;
    position: relative;
    z-index: 500;
}

.logo_container img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: white;
    position: relative;
}

.content {
    /* max-width: 343px; */
    width: 351px;
    max-width: 100%;
    height: fit-content;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
    position: relative;
}

.content span {
    font-weight: 700;
    font-size: 13px;
    line-height: 20px;
    color: #263670;
    text-shadow: 0px 5px 22px rgba(132, 132, 132, 0.13);
    margin-bottom: 15px;
}

.social {
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    min-width: 235px;
    gap: 24px;
    margin-top: 30px;
}

.social a {
    width: 110px;
    height: 110px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social a img {
    height: 45px;
    width: 45px;
}


.social a:nth-child(1) {
    background: #FFFFFF;

}

.social a:nth-child(2) {
    background: radial-gradient(126.96% 126.96% at 6.47% 97.81%, rgba(250, 143, 33, 0.67) 9%, rgba(216, 45, 126, 0.67) 78%);
}

.social a:nth-child(3) {
    background: #CFF4FF;

}

.social a:nth-child(4) {
    background: #FFFFFF;

}


.logo_projects {
    width: 307px;
    display: flex;
    flex-wrap: wrap;
    gap: 11px;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}

.logo_projects div {
    border: 0.935691px solid #CCD8FF;
    border-radius: 5.61415px;
}

.logo_projects div:not(:last-child) {
    width: 140px
}


.logo_projects .qarat img {
    width: 100px;
    height: 91px;
}

.logo_projects .kader img {
    width: 100px;
    height: 91px;
}

.logo_projects .qwaeam img {
    width: 291px;
    height: 82px;
}

.our_site {
    box-shadow: 0px 27px 77px rgba(255, 209, 92, 0.25);
    border-radius: 11px;
    background: #FFFFFF;
    width: 90%;
    padding: 7px;
    position: relative;
    z-index: 6000 !important;
}

.our_site a {
    color: #B3893E;
    text-shadow: 0px 5px 22px rgba(132, 132, 132, 0.13);
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    margin: 0 8px !important;
}


/* Media Query */

@media (max-width:321px) {
    .logo_projects {
        width: unset;
    }

    .content {
        max-width: 278px;
    }

    .logo_container {
        margin-top: 15px;
    }

    .container {
        height: unset;
    }
}



/* ///////// keyframe */
///////////////////////////////////////////////////////////////////////
@-webkit-keyframes MoveUpDown1 {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-40px);
    }
}

@keyframes MoveUpDown1 {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(-40px);
    }
}

@-webkit-keyframes MoveUpDown2 {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(40px);
    }
}


@keyframes MoveUpDown2 {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(40px);
    }
}


@-webkit-keyframes MoveCorner {
    0% {
        transform: translate(0%, 0%)
    }

    25% {
        transform: translate(-50%, 0%)
    }

    50% {
        transform: translate(-50%, 0%)
    }

    100% {
        transform: translate(-50%, 100%)
    }
}


@keyframes MoveCorner {
    0% {
        transform: translate(0%, 0%)
    }

    25% {
        transform: translate(-50%, 0%)
    }

    50% {
        transform: translate(-50%, 0%)
    }

    100% {
        transform: translate(-50%, 100%)
    }
}

@-webkit-keyframes MoveCorner-reverse {
    0% {

        left: 0px;
        top: 0px;
    }

    25% {

        left: 200px;
        top: 0px;
    }

    50% {

        left: 200px;
        top: 200px;
    }

    75% {

        left: 0px;
        top: 200px;
    }

    100% {

        left: 0px;
        top: 0px;
    }
}


@keyframes MoveCorner-reverse {
    0% {

        left: 0px;
        top: 0px;
    }

    25% {

        left: 200px;
        top: 0px;
    }

    50% {

        left: 200px;
        top: 200px;
    }

    75% {

        left: 0px;
        top: 200px;
    }

    100% {

        left: 0px;
        top: 0px;
    }
}

@keyframes MoveCorner-UpDown {
    0% {
        left: 0px;
        bottom: 0px;
    }

    25% {

        left: 200px;
        bottom: 200px;
    }

    50% {
        left: 0px;
        bottom: 0px;
    }

    75% {
        right: 200px;
        bottom: 200px;
    }

    100% {
        left: 0px;
        bottom: 0px;
    }
}


@-webkit-keyframes MoveCorner-UpDown {
    0% {
        left: 0px;
        bottom: 0px;
    }

    25% {

        left: 200px;
        bottom: 200px;
    }

    50% {
        left: 0px;
        bottom: 0px;
    }

    75% {
        right: 200px;
        bottom: 200px;
    }

    100% {
        left: 0px;
        bottom: 0px;
    }
}


#landingSection {
    .animationRow {
        position: absolute;
        bottom: -7rem;

        .row {
            height: 100%;

            .col-auto:nth-of-type(1) {
                position: relative;
                top: 0;
                left: 8rem;
                -webkit-animation: MoveUpDown2 4s infinite alternate;
                animation: MoveUpDown2 4s infinite alternate;
            }

            .col-auto:nth-of-type(2) {
                position: relative;
                top: 10rem;
                -webkit-animation: MoveUpDown1 4s infinite alternate;
                animation: MoveUpDown1 4s infinite alternate;
            }

            .col-auto:nth-of-type(3) {
                position: relative;
                top: 16rem;
                right: 12rem;
                -webkit-animation: MoveUpDown2 4s infinite alternate;
                animation: MoveUpDown2 4s infinite alternate;
            }

            .col-auto:nth-of-type(4) {
                position: relative;
                right: 2rem;
                top: 10rem;
                -webkit-animation: MoveUpDown1 4s infinite alternate;
                animation: MoveUpDown1 4s infinite alternate;
            }

            .col-auto:nth-of-type(5) {
                position: relative;
                right: 6rem;
                top: 2rem;
                -webkit-animation: MoveUpDown2 4s infinite alternate;
                animation: MoveUpDown2 4s infinite alternate;
            }

            @media screen and (max-width: 991px) {
                .col-auto:nth-of-type(1) {
                    top: -10rem;
                    left: 3rem;
                }

                .col-auto:nth-of-type(3) {
                    top: 14rem;

                }

                .col-auto:nth-of-type(5) {
                    top: 13rem;
                }
            }
        }
    }
}



@-webkit-keyframes shake {

    0%,
    10%,
    20%,
    30%,
    40%,
    50%,
    60%,
    70%,
    80%,
    90%,
    100% {
        left: 50px;
    }

    5%,
    15%,
    25%,
    35%,
    45%,
    55%,
    65%,
    75%,
    85%,
    95% {
        left: -50px;
    }
}

@keyframes shake {

    0%,
    10%,
    20%,
    30%,
    40%,
    50%,
    60%,
    70%,
    80%,
    90%,
    100% {
        left: 50px;
    }

    5%,
    15%,
    25%,
    35%,
    45%,
    55%,
    65%,
    75%,
    85%,
    95% {
        left: -50px;
    }
}

@-webkit-keyframes shake-rotate {

    0%,
    10%,
    20%,
    30%,
    40%,
    50%,
    60%,
    70%,
    80%,
    90%,
    100% {
        left: 50px;
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
    }

    5%,
    15%,
    25%,
    35%,
    45%,
    55%,
    65%,
    75%,
    85%,
    95% {
        left: -50px;
        -webkit-transform: rotate(-30deg);
        transform: rotate(-30deg);
    }
}

@keyframes shake-rotate {

    0%,
    10%,
    20%,
    30%,
    40%,
    50%,
    60%,
    70%,
    80%,
    90%,
    100% {
        left: 50px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    5%,
    15%,
    25%,
    35%,
    45%,
    55%,
    65%,
    75%,
    85%,
    95% {
        left: -50px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}
</style>
  