<template>
    <section class="registration">
        <div class="container_registration">
            <div>
                <img src="../assets/the concept.svg" width="82" height="42" alt="logo" />
            </div>

            <div class="font-weight-bold my-5 text-start" style="width:100%" v-if="isCustomerExist == 'yes'">
                <div class="gray5--text"> {{ $t('To complete the order') }}</div>
                <div class="mt-2">{{ $t('Enter your tour') }} ....</div>
            </div>

            <v-fade-transition>
                <div class="font-weight-bold my-5 text-start" style="width:100%" v-if="isCustomerExist == 'no'">
                    <div class="gray5--text"> {{ $t('You are a new customer, welcome') }}</div>
                    <div class="mt-2">{{ $t('Please write your name to complete the reservation') }}</div>
                </div>
            </v-fade-transition>

            <v-fade-transition>
                <v-form ref="form" class="card_container gray12 pa-2 mt-6" @submit.prevent="getData">

                    <span class="text-caption font-weight-bold d-block my-2"> {{ $t('Mobile number') }}</span>
                    <TextInput :value="mobile" @input="mobile = $event" type="number" color="accent" />

                    <span v-if="isCustomerExist == 'no'" class="text-caption font-weight-bold d-block my-2">
                        {{ $t('username') }}</span>
                    <TextInput v-if="isCustomerExist == 'no'" :value="username" @input="username = $event" color="accent"
                        :keydownEnter="getData" />

                </v-form>
            </v-fade-transition>

            <div class="navigation d-flex justify-space-between mt-13 mb-3 px-5" style="width:100%">
                <v-btn text elevation="0" width="101" height="45" class="rounded-pill" @click="previous">
                    {{ $t('previous') }}</v-btn>

                <v-btn depressed color="accent" width="101" :disabled="validate" height="45" class="rounded-pill"
                    @click="getData">
                    {{ $t('Booking confirmation') }}</v-btn>
            </div>

        </div>
    </section>
</template>
  
<script>
import TextInput from '@/inputs/TextInput.vue';

export default {
    name: "Registration",

    data: () => ({
        valid: false,
        mobile: null,
        username: null,
        isCustomerExist: 'yes',
        params: {}
    }),
    props: {
        navigationTab: { default: Function },
        data: { default: Object }
    },
    components: {
        TextInput
    },
    watch: {
        data() {
            this.isCustomerExist = 'yes'
        }
    },
    methods: {
        getData() {
            if (this.isCustomerExist === 'yes') {
                this.$api.GET_METHOD(`search_customer?word=${this.mobile}`).then((response) => {
                    if (response.check) {
                        if (response.data.length !== 0) {
                            this.isCustomerExist = 'yes'
                            this.params.name = null;
                            this.params.customer_id = response.data[0].id
                            this.params.mobile = this.mobile
                            this.navigationTab(1, { 'params': this.params })
                        } else if (response.data.length === 0) {
                            this.isCustomerExist = 'no'
                        }
                    }
                })
            } else if (this.isCustomerExist === 'no') {
                this.params.customer_id = 0
                this.params.mobile = this.mobile
                this.params.name = this.username;
                this.navigationTab(1, { 'params': this.params })
            }

        },
        handelParams() {
            return {
                ...this.data.params,
                'customer_id': null,
                'mobile': this.mobile,
                'username': this.username
            }
        },
        previous() {
            this.navigationTab(-1)
        }
    },
    computed: {
        validate() {
            return this.isCustomerExist === 'no' ? (!this.mobile || !this.username) : !this.mobile
        }
    },
    mounted() {
        this.params = {
            ...this.data.params,
            'customer_id': null,
            'mobile': this.mobile,
            'username': this.username
        }
    }
};
</script>
<style lang="scss" scoped>
.container_registration {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 14px;
    height: 100dvh;
    position: relative;

}

.card_container {
    width: 100%;
    border-radius: 4px;
    background: #F9F9F9;
    border: 1px solid #D1D1D1 !important;
}




.card {
    border: 1.25017px solid #DEDEDE;
    border-radius: 7px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.navigation {
    position: absolute;
    bottom: 2%;
}
</style>