import Vue from "vue";
import VueRouter from "vue-router";

// Views :-
import Website from "@/views/Website/Website";


// Modules Children Routes :-

import { WebsiteModuleRoutes } from "@/router/WebsiteModuleRoutes";

Vue.use(VueRouter);

// Routes :-
const routes = [
  {
    path: "/",
    component: Website,
    children: [...WebsiteModuleRoutes],
  },
  {
    path: "/ar",
    component: Website,
    children: [...WebsiteModuleRoutes],
  },
  {
    path: "/en",
    component: Website,
    children: [...WebsiteModuleRoutes],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
