<template>
  <v-app v-resize="onResize" v-scroll="onScroll">

    <v-main class="pa-0">
      <!-- <SplashLoader v-if="$store.state.splashScreen == true" /> -->



      <v-container fluid class="pa-0">
        <router-view></router-view>
      </v-container>


      <v-snackbar multi-line color="#333333" v-model="$store.state.showSnackbar" top :timeout="4000">
        <div class="text-center primary--text justify-center d-flex align-center">
          <v-icon color="primary" size="18" v-if="$store.state.snackbarType == 'info'">mdi-information</v-icon>
          <v-icon color="primary" size="18" v-if="$store.state.snackbarType == 'warning'">mdi-alert</v-icon>
          <v-icon color="primary" size="18" v-if="$store.state.snackbarType == 'error'">mdi-alert-octagon</v-icon>
          <div class="mx-1">{{ $t($store.state.snackbarTitle) }}</div>
        </div>
        <div class="body-1" v-for="(message, index) in $store.state.snackbarMessages" :key="index">{{ $t(message) }}
        </div>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<style lang="scss">
@import "./scss/variables.scss";
@import "./scss/core.scss";
</style>
<script>

// import SplashLoader from "@/components/ui/SplashLoader.vue";
export default {
  name: "App",
  components: {
    // SplashLoader 
  },
  data: () => ({
    isLoading: false,
    registration: null,
    updateExists: false,
    refreshing: false,
  }),
  mounted() {
    this.$i18n.locale = localStorage.getItem('language') ? localStorage.getItem('language') : 'ar';
    if (this.$route.path == '/en') {
      localStorage.setItem("language", 'en');
      this.$i18n.locale = 'en';
      this.$vuetify.rtl = false;
      this.$store.state.language = 'en';
      this.$store.state.Settings.language = 'en';
    } else {
      localStorage.setItem("language", 'ar');
      this.$i18n.locale = 'ar';
      this.$vuetify.rtl = true;
      this.$store.state.language = 'ar';
      this.$store.state.Settings.language = 'ar';
    }
    document.addEventListener('swUpdated', this.swUpdated, { once: true });
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return
      this.refreshing = true
      window.location.reload()
    })
    document.addEventListener('swReady', this.swReady, { once: true });
  },
  watch: {
    $route() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.commit('UPDATE_CLIENT', this.$route.query.clientID)
    },
    showSnackbar() {
      if (!this.showSnackbar) {
        setTimeout(() => {
          this.$store.state.snackbarType = 'info';
          this.$store.state.snackbarTime = 4500;
          this.$store.state.snackbarTitle = null;
          this.$store.state.snackbarMessages = [];
        }, 100);
      }
    }
  },
  created() {
    document.addEventListener('swUpdateFound', this.swUpdateFound, { once: true });
  },
  computed: {
    showSnackbar() {
      return this.$store.state.showSnackbar
    }
  },
  methods: {
    onResize() {
      this.$store.state.windowSize = { x: window.innerWidth, y: window.innerHeight, offsetTop: 0 }
    },
    onScroll() {
      console.log(window.pageYOffset);
      this.$store.state.windowSize.offsetTop = window.pageYOffset
    },
    swUpdated(event) {
      this.registration = event.detail
      this.refreshApp()
    },
    swUpdateFound() {

    },
    swOffline() {
      this.$store.state.networkConnection = false;
      this.$global.ShowAlert('info', this.$i18n.t('No Internet Connection'));
    },
    refreshApp() {
      this.updateExists = false;
      if (!this.registration || !this.registration.waiting) return
      this.$router.push('/')
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
    },
    swReady() {
      if (this.$route.fullPath == '/system_update') {
        this.$router.push('/')
      }
    }
  },
};
</script>
