import Vue from "vue";
import VueLazyload from 'vue-lazyload'
const loadImage = require('@/assets/logo.svg')
const errorImage = require('@/assets/logo.svg')
Vue.use(VueLazyload, {
    lazyComponent: true,
    preLoad: 1.3,
    error: errorImage,
    loading: loadImage,
    attempt: 1
});

export default ({
});