<template>
    <section>
        <div class="container_ConfirmLoading">
            <img src="../assets/Ar_logo.svg" height="500" alt="logo" />
            <div class="gray6--text font-weight-bold text-h4 mt-n15 d-flex align-end">
                {{ $t('Your appointment is being booked') }}
                <img width="25" src="../assets/Ellipsis-1s-200px (1).svg" />
            </div>
        </div>
    </section>
</template>
  
<script>

export default {
    name: "ConfirmLoading",

    data: () => ({
        form: {}
    }),
    props: {
        navigationTab: { default: Function },
        data: { default: Object }
    },
    components: {
    },
    computed: {},
    mounted() {
        console.log('data', this.data);
        this.form = {
            customer_id: this.data.params.customer_id,
            barber_customer_mobile: this.data.params.mobile,
            barber_customer_name: this.data.params.name,
            barber_customer_notes: null,
            barber_id: this.data.params.barber_id,
            services: this.data.params.services,
            branch_id: this.data.branch_id,
            barber_booking_date: this.data.params.booking_date,
            start_time: this.data.params.time_id,
        }
        this.getData()
    },
    methods: {
        getData() {
            this.$api.POST_METHOD(`make_booking`, this.form,false).then((response) => {
                if (response.check) {
                    this.navigationTab(1)
                }
            })
        }
    },
};
</script>
<style lang="scss" scoped>
.container_ConfirmLoading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 14px;
    height: 100dvh;

    div img {
        transform: rotate(180deg);
    }
}
</style>