<template>
    <section>
        <div class="container_appointmentTime">
            <div class="pa-5">
                <img src="../assets/the concept.svg" width="82" height="42" alt="logo" />
            </div>

            <div class="font-weight-bold my-5 text-start px-5" style="width:100%">
                {{ $t('Choose the hour you want the service in') }}...</div>

                <!-- :style="{ height: $store.state.windowSize.y - 300 + 'px' }" -->
            <div class="card_container d-flex flex-wrap justify-center align-center mt-5">
                <div class="card font-weight-medium text-caption ma-2"
                    :class="{ 'card_active': selectedTime && date.time_id === selectedTime }" v-for="date in data.date"
                    :key="date" @click="select(date)">
                    {{ date.time_title }}
                </div>
            </div>
            <v-alert v-if="!Object.keys(data.date).length" color="#bf9d5f" outlined :value="true">
                {{ $t('no times available for this barber')}}
            </v-alert>
            <div class="navigation d-flex justify-space-between mt-13 mb-3 px-5" style="width:100%">
                <v-btn text elevation="0" width="101" height="45" class="rounded-pill" @click="previous">
                    {{ $t('previous') }}</v-btn>

                <v-btn depressed color="accent" width="101" :disabled="!selectedTime" height="45" class="rounded-pill"
                    @click="next">
                    {{ $t('next') }}</v-btn>
            </div>

        </div>
    </section>
</template>
  
<script>

export default {
    name: "AppointmentTime",

    data: () => ({
        selectedTime: null,
        time: null,
        isSelected: false,
    }),
    props: {
        navigationTab: { default: Function },
        data: { default: Object }
    },
    methods: {
        next() {
            if (this.selectedTime && !this.isSelected) {
                this.isSelected = true;
                setTimeout(() => this.isSelected = false, 5000)
                this.navigationTab(1, { 'params': { ...this.data.params, 'time': this.time, 'time_id': this.selectedTime } })
            }
        },
        previous() {
            this.navigationTab(-1)
        },
        select(date) {
            this.time = date.time_title
            this.selectedTime = date.time_id
            this.next()
        }
    },

};
</script>
<style lang="scss" scoped>
.container_appointmentTime {
    display: flex;
    flex-direction: column;
    padding: 14px;
    height: 100dvh;
    align-items: center;
}

.card_container {
    overflow: auto;
    align-content: flex-start;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

}

.card {
    border: 1.25017px solid #DEDEDE;
    border-radius: 7px;
    padding: 12px 22px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.card:hover {
    background-color: #FFE5B6;
    border-color: transparent;
    color: black !important;
}

.card_active {
    background-color: #FFE5B6;
    border-color: transparent;
    color: black !important;
}

.navigation {
    position: absolute;
    bottom: 2%;
}
</style>