<template>
    <section class="checkout">
        <div class="container_checkout">
            <div class="pb-9 pt-3">
                <img src="../assets/the concept.svg" width="82" height="42" alt="logo" />
            </div>

            <div> <v-divider></v-divider></div>

            <v-row class="py-3">
                <v-col cols="6" class="text-start ">
                    <div class="gray8--text mb-1"> {{ $t('date') }} </div>
                    <div class="font-weight-bold"> {{ data.params.booking_date }}</div>
                </v-col>
                <v-col cols="6" class="text-end">
                    <div class="gray8--text mb-1"> {{ $t('Entry time') }} </div>
                    <div class="font-weight-bold">{{ data.params.time }}</div>
                </v-col>

                <!-- Services -->
                <!-- :style="{ height: $store.state.windowSize.y - 460 + 'px' }" -->
                <v-col cols="12" class="checkout_col_services" >
                    <v-row class="checkout_services pa-2 my-2 gray11">
                        <v-col cols="12" class="text-start font-weight-bold">
                            {{ $t('services') }}
                        </v-col>
                        <v-row class="mx-1" v-for="service in services" :key="service.id">
                            <v-col cols="8" class="text-start gray8--text">
                                {{ service.name }}
                            </v-col>
                            <v-col cols="4" class="text-end font-weight-bold">
                                {{ service.price_with_vat | float }} {{ $t('Riyal') }}
                            </v-col>
                        </v-row>

                    </v-row>
                </v-col>
                <!-- End Services -->

                <v-col cols="12">
                    <v-row class="gray8--text" justify="space-between">
                        <v-col cols="auto" class="text-start">
                            {{ $t('The total before tax') }}
                        </v-col>
                        <v-col cols="auto" class="text-end">
                            {{ price_without_vat | float }} {{ $t('Riyal') }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row class="gray8--text" justify="space-between">
                        <v-col cols="auto" class="text-start">
                            {{ $t('Vat') }}
                        </v-col>
                        <v-col cols="auto" class="text-end">
                            {{ (price_with_vat - price_without_vat) | float }} {{ $t('Riyal') }}
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="12">
                    <v-row justify="space-between">
                        <v-col cols="auto" class="text-start gray8--text">
                            {{ $t('Total inclusive of tax') }}
                        </v-col>
                        <v-col cols="auto" class="text-end font-weight-bold">
                            {{ price_with_vat | float }} {{ $t('Riyal') }}
                        </v-col>
                    </v-row>
                </v-col>

            </v-row>

            <div class="navigation d-flex justify-space-between align-center mt-13 mb-3 " style="width:91%">
                <v-btn text elevation="0" width="101" height="45" class="rounded-pill" @click="navigationTab(-1)">
                    {{ $t('previous') }}</v-btn>

                <v-btn depressed color="accent" width="101" height="45" class="rounded-pill pa-0" @click="navigationTab(1)">
                    {{ $t('next') }}</v-btn>
            </div>

        </div>
    </section>
</template>
  
<script>

export default {
    name: "CheckOut",

    data: () => ({
        services: [],
        price_with_vat:null,
        price_without_vat:null
    }),
    props: {
        navigationTab: { default: Function },
        data: { default: Object }
    },
    mounted() {
        this.checkoutData()
    },
    methods: {
        checkoutData() {
            let selectedServices = this.data.selectedServices
            this.services = this.data.services.filter(service => selectedServices.some(selected => selected === service.id))
            this.price_with_vat = this.services.reduce((prev,currant)=> prev + currant.price_with_vat,0)
            this.price_without_vat = this.services.reduce((prev,currant)=> prev + currant.price_without_vat,0)
            console.log('data', this.data);
            console.log('services', this.services);
        }
    },
    watch:{
        data(){
            this.checkoutData()
        }
    }
};
</script>
<style lang="scss" scoped>
.checkout {
    // margin: 25px;
    padding: 15px;
}

.container_checkout {
    text-align: center;
    padding: 2px;
    height: 100dvh;
}

.checkout_col_services {
    overflow: auto;
    overflow-x: hidden;
    height: 45dvh;
}

.checkout_services {
    align-content: flex-start;
}

.card {
    border: 1.25017px solid #DEDEDE;
    border-radius: 7px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.navigation {
    position: absolute;
    bottom: 3%;
}
</style>