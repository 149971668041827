// Website Module Routes:-
import HomeModule from "@/views/Website/Home/HomeModule";



export const WebsiteModuleRoutes = [
  // Home
  {
    path: "/",
    name: "HomeModule",
    component: HomeModule,
    meta: {
      title: "home",
    },
  },
  // {
  //   path: '/:pathMatch(.*)*', 
  //   name: 'NotFound', 
  //   component: HomeModule, 
  //   meta: {
  //     title: "home",
  //   },
  // },
];
